import ArtistListWithImg from '../../components/artist/ArtistListWithImg';
import AnimatedWrapper from 'components/common/AnimatedWrapper';

const ArtistPage = () => {
  return (
    <AnimatedWrapper>
      <ArtistListWithImg />
    </AnimatedWrapper>
  );
};

export default ArtistPage;
