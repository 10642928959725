import { Card, CardMedia } from "@mui/material";

type ImgItemProps = {
  content: string;
};
const ImgItem = ({ content }: ImgItemProps) => {
  return (
    <Card sx={{ maxWidth: "100%" }}>
      <CardMedia sx={{ height: 700 }} image={content} />
    </Card>
  );
};

export default ImgItem;
