import Carousel from 'react-material-ui-carousel';
import ImgItem from '../../UI/ImgItem';
import { Grid, CardActions, Button, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const SliderSection = () => {
  const { t } = useTranslation();
  const items = [
    {
      name: 'soma exhibition2',
      description: "gallery SOMA's casual exhibition.",
      content:
        'https://gallery-soma-assets.s3.ap-northeast-2.amazonaws.com/test1/gallery_SOMA_exhibition2.webp',
    },
    {
      name: 'soma exhibition',
      description: "gallery SOMA's casual exhibition.",
      content:
        'https://gallery-soma-assets.s3.ap-northeast-2.amazonaws.com/test1/gallery_SOMA_exhibition.webp',
    },
    {
      name: 'mom and daughter',
      description:
        'a mom and her daughter walking around the gallery viewing pieces of arts.',
      content:
        'https://gallery-soma-assets.s3.ap-northeast-2.amazonaws.com/test1/mom_n_daughter_viewing.webp',
    },
    {
      name: 'soma outerior',
      description: 'the outerior of gallery SOMA.',
      content:
        'https://gallery-soma-assets.s3.ap-northeast-2.amazonaws.com/test1/SOMA_outerior_1.webp',
    },
    {
      name: 'soma art displaying',
      description:
        'gallery SOMA displaying their artworks at the art competition.',
      content:
        'https://gallery-soma-assets.s3.ap-northeast-2.amazonaws.com/test1/SOMA_art_displaying.webp',
    },
  ];

  return (
    <>
      <Box sx={{ marginTop: '-1rem' }}>
        <Carousel sx={{ zIndex: 'mobile stepper' }}>
          {items.map((item, i) => (
            <ImgItem content={item.content} key={item.description}></ImgItem>
          ))}
        </Carousel>
      </Box>
      <Grid container justifyContent="center">
        <CardActions>
          <Link to="/merchandises">
            <Button
              variant="contained"
              size="large"
              content="Start Exploring"
              sx={{ borderRadius: '0' }}
            >
              {t(`s.main.direct.explore`)}
            </Button>
          </Link>
        </CardActions>
      </Grid>
    </>
  );
};

export default SliderSection;
