import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button } from '@mui/material';
import { styled } from '@mui/system';
import { useDispatch } from 'react-redux';
import { langActions } from 'store/lang-redux';

const LanguageButton = styled(Button)(({ theme }) => ({
  margin: '0 5px',
  padding: '8px 16px',
  borderRadius: '20px',
  textTransform: 'none',
  borderColor: theme.palette.primary.main,
  color: 'black',
  '&.selected': {
    backgroundColor: 'white',
    color: 'black',
  },
}));

const LanguageSwitcher: React.FC = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    if (lng == 'en') {
      dispatch(langActions.setLanguage(0));
    } else {
      dispatch(langActions.setLanguage(1));
    }
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', margin: '20px' }}>
      <LanguageButton
        variant="outlined"
        className={i18n.language === 'en' ? 'selected' : ''}
        onClick={() => changeLanguage('en')}
      >
        English
      </LanguageButton>
      <LanguageButton
        variant="outlined"
        className={i18n.language === 'ko' ? 'selected' : ''}
        onClick={() => changeLanguage('ko')}
      >
        한국어
      </LanguageButton>
    </Box>
  );
};

export default LanguageSwitcher;
