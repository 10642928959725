import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
    Avatar,
    Box,
    Divider,
    Typography,
    Button,
    ImageList,
    ImageListItem,
    CircularProgress,
} from '@mui/material';
import useCDNResource from '../../utils/useCDNResource';
import { ApiServer } from '../../utils/axios';
import ArtWorkItem from 'models/artwork/ArtWorkItem';
import useWindowDimensions from '../../utils/useWindowDimension';
import useMountEffect from '../../hooks/useMountEffect';
import { useTranslation } from 'react-i18next';
import ArtworkItem from 'components/artworks/ArtworkItem';
import { loadingActions } from 'store/loading-redux';
import { useDispatch, useSelector } from 'react-redux';
import { GLOBAL_LOADED, GLOBAL_LOADING } from 'store/loading-redux';
import { RootState } from "../../store/redux";

type textType = {
    id: number;
    createdAt: string;
    artistId: number;
    text: string;
    textType: string;
};

type artistInformationType = {
    id: number;
    name: string;
    nameKo: string;
    nameEn: string;
    type: string;
    physicalResidence: string;
    profileImagePath: string;
    representativeImagePath: string;
    createdAt: string;
    artworks: ArtWorkItem[];
    mainResidence: string;
    bio: textType[];
    description: textType[];
};

enum TextType {
    Normal = 'NORMAL',
    Bold = 'BOLD',
}

const ArtistIndividualPage = () => {
    const { id } = useParams();
    const [artistInformation, setArtistInformation] = useState<artistInformationType>();
    const [artistProfilePicPath, setArtistProfilePicPath] = useState<string>();
    const [isDescriptionShowing, setIsDescriptionShowing] = useState<boolean>(true); // 기본값을 true로 설정
    const [isBiographyShowing, setIsBiographyShowing] = useState<boolean>(false);
    const { getCDNResource } = useCDNResource();
    const { width } = useWindowDimensions(); // Get window dimensions
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const loading = useSelector((state: RootState) => state.loading.loading);

    useMountEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (id) {
            dispatch(loadingActions.setLoading(GLOBAL_LOADING));
            ApiServer.get(`/artist/${id}`)
                .then(({ data: { data, status } }) => {
                    if (status?.code === 'OK') {
                        setArtistInformation({
                            id: data.id,
                            name: data.name,
                            nameKo: data.nameKo,
                            nameEn: data.nameEn,
                            type: data.type,
                            physicalResidence: data.physicalResidence,
                            profileImagePath: data.profileImagePath,
                            representativeImagePath: data.representativeImagePath,
                            createdAt: data.createdAt,
                            artworks: data.artworks,
                            mainResidence: data.mainResidence,
                            bio: data.bio,
                            description: data.description,
                        });
                    } else {
                        console.log(status.description);
                    }
                    dispatch(loadingActions.setLoading(GLOBAL_LOADED));
                })
                .catch((e) => {
                    console.error(e);
                    dispatch(loadingActions.setLoading(GLOBAL_LOADED));
                });
        }
    }, [id, dispatch]);

    useEffect(() => {
        if (artistInformation) {
            setArtistProfilePicPath(getCDNResource(artistInformation.profileImagePath));
        }
    }, [artistInformation, getCDNResource]);

    const descToggleHandler = () => {
        setIsBiographyShowing(false);
        setIsDescriptionShowing(true);
    };

    const bioToggleHandler = () => {
        setIsDescriptionShowing(false);
        setIsBiographyShowing(true);
    };

    return (
        <Box sx={{ marginTop: '10rem' }}>
            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box>
                            <Avatar
                                src={artistProfilePicPath}
                                sx={{ width: '10rem', height: '10rem', marginLeft: '2rem' }}
                            />
                        </Box>
                        <Box
                            sx={{ display: 'flex', flexDirection: 'column', marginLeft: '2rem' }}
                        >
                            <Typography variant={'h4'}>{artistInformation?.name}</Typography>
                            <Typography>{`${artistInformation?.physicalResidence}, ${artistInformation?.mainResidence}`}</Typography>
                        </Box>
                    </Box>
                    <Divider sx={{ mt: '1rem', mb: '1rem' }} />
                    <Box sx={{ display: 'flex', marginLeft: '1rem' }}>
                        <Button
                            sx={{
                                padding: '1rem',
                                color: isDescriptionShowing ? 'inherit' : 'gray',
                                fontWeight: isDescriptionShowing ? 'bold' : 'normal',
                                transition: 'color 0.3s, font-weight 0.3s',
                            }}
                            onClick={descToggleHandler}
                        >
                            {t('w.artist.info.description')}
                        </Button>
                        <Button
                            sx={{
                                padding: '1rem',
                                color: isBiographyShowing ? 'inherit' : 'gray',
                                fontWeight: isBiographyShowing ? 'bold' : 'normal',
                                transition: 'color 0.3s, font-weight 0.3s',
                            }}
                            onClick={bioToggleHandler}
                        >
                            {t('w.artist.info.biography')}
                        </Button>
                    </Box>

                    <Box
                        sx={{
                            width: { md: '70%', lg: '40%' },
                            marginLeft: '2rem',
                            marginRight: '1rem',
                            transition: 'opacity 0.5s',
                            opacity: isDescriptionShowing ? 1 : 0,
                        }}
                    >
                        {isDescriptionShowing &&
                            artistInformation &&
                            artistInformation.description.map((item) =>
                                item.textType === TextType.Normal ? (
                                    <div key={item.id}>{item.text}</div>
                                ) : (
                                    <div key={item.id}>
                                        <strong>{item.text}</strong>
                                    </div>
                                )
                            )}
                    </Box>

                    <Box
                        sx={{
                            width: { md: '70%', lg: '40%' },
                            marginLeft: '2rem',
                            marginRight: '1rem',
                            transition: 'opacity 0.5s',
                            opacity: isBiographyShowing ? 1 : 0,
                        }}
                    >
                        {isBiographyShowing &&
                            artistInformation &&
                            artistInformation.bio.map((item) =>
                                item.textType === TextType.Normal ? (
                                    <div key={item.id}>{item.text}</div>
                                ) : (
                                    <div key={item.id}>
                                        <strong>{item.text}</strong>
                                    </div>
                                )
                            )}
                    </Box>

                    <section>
                        <Box sx={{ ml: '2rem' }}>
                            <Typography variant="h6" sx={{ mt: '1rem', mb: '1rem' }}>
                                {t('w.artist.info.artworks')}
                            </Typography>
                            {artistInformation && (
                                <ImageList
                                    variant="masonry"
                                    cols={width >= 1000 ? 3 : 2}
                                    gap={width >= 650 ? 80 : 20}
                                >
                                    {artistInformation.artworks.map((item) => (
                                        // <ArtworksLoading width={210} height={500} key={item.id}>
                                            <ImageListItem key={item.id}>
                                                <Link to={`/merchandises/${item.id}`}>
                                                    <ArtworkItem artwork={item} />
                                                </Link>
                                            </ImageListItem>
                                        // </ArtworksLoading>
                                    ))}
                                </ImageList>
                            )}
                        </Box>
                    </section>
                </>
            )}
        </Box>
    );
};

export default ArtistIndividualPage;
