import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, Grid, CircularProgress } from '@mui/material';
import BasicResponse from '../../models/common/BasicResponse';
import Artist from '../../models/artist/Artist';
import { Link } from 'react-router-dom';
import useCDNResource from '../../utils/useCDNResource';
import useWindowDimensions from '../../utils/useWindowDimension';
import styles from './ArtistListWithImg.module.css';
import { ApiServer } from '../../utils/axios';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

const INITIAL_IMG_PATH = 'https://gallery-soma-assets.s3.ap-northeast-2.amazonaws.com/artwork/Jinkyung+Hu/%EC%98%A4%EB%A1%AF%EC%9D%B4+%EB%8B%B4%EB%8B%A4/%E1%84%92%E1%85%A5%E1%84%8C%E1%85%B5%E1%86%AB%E1%84%80%E1%85%A7%E1%86%BC-%E1%84%8B%E1%85%A9%E1%84%85%E1%85%A9%E1%86%BA%E1%84%8B%E1%85%B5+%E1%84%83%E1%85%A1%E1%86%B7%E1%84%83%E1%85%A1-%E1%84%8C%E1%85%A1%E1%86%A8%E1%84%91%E1%85%AE%E1%86%B7%E1%84%89%E1%85%A1%E1%84%8C%E1%85%B5%E1%86%AB/s%E1%84%92%E1%85%A5%E1%84%8C%E1%85%B5%E1%86%AB%E1%84%80%E1%85%A7%E1%86%BC_%E1%84%8B%E1%85%A9%E1%84%85%E1%85%A9%E1%86%BA%E1%84%8B%E1%85%B5%E1%84%83%E1%85%A1%E1%86%B7%E1%84%83%E1%85%A1(PE+2023+%2337)_50F_%E1%84%8B%E1%85%A1%E1%84%8F%E1%85%B3%E1%84%85%E1%85%B5%E1%86%AF%E1%84%92%E1%85%A9%E1%86%B8_2023.jpg';

const ArtistListWithImg = () => {
  const [artists, setArtists] = useState<Artist[]>([]);
  const [displayingImgPath, setDisplayingImgPath] = useState(INITIAL_IMG_PATH);
  const [artistId, setArtistId] = useState<number | null>(null);
  const { getCDNResource } = useCDNResource();
  const { width } = useWindowDimensions();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleArtistSelect = (id: number) => {
    const selectedArtist = artists.find((item) => item.id === id);
    setArtistId(id);
    if (selectedArtist) {
      const displayingImgUrl = selectedArtist.representativeImagePath;
      setDisplayingImgPath(getCDNResource(displayingImgUrl || 'defaultImage.jpg'));
    }
  };

  useEffect(() => {
    setIsLoading(true);
    ApiServer.get<BasicResponse<Artist[]>>('/artist/all')
        .then(({ data: { data, status } }) => {
          if (status.code === 'OK') {
            setArtists(data);
          } else {
            console.error(status.description);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
        });
  }, []);

  const getImgClass = () => {
    if (width > 1000) return styles.displayingImgWhenBig;
    if (width > 540) return '';
    return styles.displayingImgWhenSmall;
  };

  return (
      <Box>
        <Typography display="flex" justifyContent="center" variant="h2" color="secondary">
          {t('t.artists.main')}
        </Typography>
        {isLoading ? (
            <Box sx={{display:"flex", justifyContent:"center", alignItems:"center"}}>
            <CircularProgress color="inherit" />
            </Box>
        ) : (
            <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: { xs: '2rem', lg: '10rem' } }}>
              {artists.map((item) => (
                  <Grid container spacing={2} alignItems="center" key={item.id} sx={{ position: 'relative' }}>
                    <Grid item xs={12} sm={6}>
                      <Link to={`/artists/${item.id}`} key={item.id}>
                        <Button
                            onClick={() => handleArtistSelect(item.id)}
                            onMouseOver={() => handleArtistSelect(item.id)}
                            sx={{
                              width: '100%',
                              padding: '0.5rem 0',
                              marginBottom: '1rem',
                              color: 'black',
                              borderColor: 'black',
                              backgroundColor: 'transparent',
                              transition: 'background-color 0.3s, color 0.3s',
                              '&:hover': {
                                backgroundColor: '#f0f0f0',
                                color: '#000',
                              },
                            }}
                            className={styles.button}
                        >
                          {item.name}
                        </Button>
                      </Link>
                    </Grid>
                    {item.id === artistId && (
                        <Grid item xs={12} sm={6} sx={{ position: 'absolute', right: 0 }}>
                          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Link to={`/artists/${item.id}`}>
                              {/*<ArtworksLoading width={300} height={300}>*/}
                                <div className={`${styles.imgContainer} ${getImgClass()}`}>
                                  <img src={displayingImgPath} alt={item.name} className={styles.fadeIn} />
                                </div>
                              {/*</ArtworksLoading>*/}
                            </Link>
                          </Box>
                        </Grid>
                    )}
                  </Grid>
              ))}
            </Box>
        )}
      </Box>
  );
};

export default ArtistListWithImg;
