import React, { useEffect, useState, useCallback } from 'react';
import {
  Box,
  Button,
  Typography,
  InputLabel,
  MenuItem,
  FormControl,
  Checkbox,
  FormControlLabel,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { useRouteLoaderData, useParams, useNavigate } from 'react-router-dom';
import useCDNResource from '../../utils/useCDNResource';
import ArtWorkItem from '../../models/artwork/ArtWorkItem';
import ProductImage from '../Images/ProductImage';
import PageableData from '../../models/pageable/PageableData';
import BasicResponse from '../../models/common/BasicResponse';
import ArtworkItemCart from '../../models/artwork/ArtworkItemCart';
import { useDispatch } from 'react-redux';
import { cartActions } from '../../store/cart-redux';
import ArtworkOverlay from '../../components/overlay/ArtworkOverlay';
import { ArtworkOrder } from 'models/order'; // type needed to request payment
import { ApiServer } from 'utils/axios';
import { useTranslation } from 'react-i18next';
import { useSelector, UseSelector } from 'react-redux/es/hooks/useSelector';
import { RootState } from 'store/redux';

const IndividualArtWorkCard = () => {
  const navigate = useNavigate();
  const artworkData = useRouteLoaderData('individual-artwork') as ArtWorkItem;
  const [size, setSize] = useState('');
  const { getCDNResource } = useCDNResource();
  const [shoppingCartCount, setShoppingCartCount] = useState(0);
  const [items, setItems] = useState<ArtworkItemCart[]>();
  const artworkImgPath = artworkData.medias[0]?.media.path;
  const { artworkId } = useParams<string>();
  const [artworkItems, setArtworkItems] = useState<
    PageableData<ArtWorkItem[]> | undefined
  >(undefined);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {isAuthenticated} = useSelector((state:RootState) => state.auth);

  useEffect(() => {
    console.log('ARTWORKDATA: ', artworkData);
  }, [artworkData]);

  const handleSizeChange = (event: SelectChangeEvent) => {
    setSize(event.target.value as string);
  };

  //to request a order, we need to post an array of each artwork's id
  const order = useCallback(() => {
    if(!isAuthenticated){
      navigate('/signup')
    }

    ApiServer.post<BasicResponse<ArtworkOrder>>('/order', {
      items: [artworkData.id],
    })
      .then(({ data: { data } }) => {
        navigate(`/order/${data.orderId}`);
      })
      .catch((e) => {
        console.log('ERROR: ', e);
      });
  }, [artworkData.id, isAuthenticated, navigate]);

  const addCartHandler = useCallback(
    (artworkId: string | undefined, size: number) => {
      if (!size) {
        alert(t('Please select a quantity.'));
        return;
      }

      if (artworkId === undefined) {
        return;
      }

      setShoppingCartCount((prev) => prev + 1);
      ApiServer.post(`/artwork/shopping-cart`, {
        artworkItemId: artworkId,
        size: size,
        shippingMethodId: isCheckboxChecked ?  "2" :  "1",
      })
        .then(({ data: { data } }) => {
          dispatch(cartActions.addCart({ artwork: artworkData }));
          console.log('artwork added id: ', artworkId);
          console.log(data);
        })
        .catch((error) => {
          if (error.response && error.response?.status === 401) {
            // Check if the error is a 401 Unauthorized
            navigate('/signup'); // Redirects to the sign-up page
          } else {
            console.error('Error adding artwork to cart:', error); // Handle other types of errors or log them
          }
        });
    },
    [artworkData, dispatch, isCheckboxChecked, navigate, t]
  );

  useEffect(() => {
    ApiServer.get<BasicResponse<ArtworkItemCart[]>>('/artwork/category/all')
      .then(({ data: { data } }) => {
        setItems(data);
        return data;
      })
      .then((data) => {
        console.log(data);
      })
      .catch(() => {
        console.log('error occurred');
      });
  }, []);

  const checkboxChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsCheckboxChecked(event.target.checked);
  };

  useEffect(() => {
    console.log("checkbox change!! ", isCheckboxChecked);
  });

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' }, // Stack on smaller screens, side by side on larger screens
        alignItems: 'flex-start', // Aligns children at the top
        justifyContent: 'center', // Centers children horizontally
        marginTop: { xs: '10rem', sm: '12rem' },
        gap: { md: 3 }, // Adds space between children on medium screens and up
      }}
    >
      <Box
        sx={{
          display: 'inline-block', // Ensures container only takes up as much space as its content
          position: 'relative',
          width: '100%', // Takes full width in a column layout on small screens
          maxWidth: { md: '45rem' }, // Maximum width on medium screens and up
        }}
      >
        {/*<ArtworksLoading width={500} height={500}>*/}
            <ProductImage
              imgUrl={getCDNResource(artworkData.medias[0]?.media.path)}
            />
        {/*</ArtworksLoading>*/}
        <ArtworkOverlay
          artwork={artworkData}
          onOverlayClick={() => navigate(`/merchandises/${artworkData.id}`)}
        />
      </Box>
      <Box
        sx={{
          display: { lg: 'flex' },
          alignItems: { lg: 'flex-start' },
          height: { lg: '100vh' },
        }}
      >
        <Box
          sx={{
            bgcolor: 'white',
            mt: { xs: '2rem', lg: '0' },
            width: {
              xs: '100vw',
              sm: '100vw',
              md: '45rem',
              lg: '23rem',
              xl: '29rem',
            },
            position: 'relative',
          }}
        >
          <Box sx={{ padding: { xs: '1.5rem', md: '2rem' } }}>
            <Box sx={{ marginBottom: '1rem' }}>
              <Typography variant={'h4'}>
                <b>{artworkData.artwork.title}</b>
              </Typography>
              <Typography>{artworkData.artwork.artist.name}</Typography>
              <Typography>{artworkData.artwork.artist.country}</Typography>
            </Box>
            <Box sx={{ marginBottom: '1rem' }}>
              <Typography>{artworkData.material}</Typography>
              <Typography>
                {t(`w.common.info.dimensions`)}: {artworkData.width}
                {t(`w.common.info.height`)} x {artworkData.height}
                {t(`w.common.info.width`)} x {artworkData.depth}
                {t(`w.common.info.depth`)} cm
              </Typography>
              <Typography>{t(`s.merchandise.info.ships-in-box`)}</Typography>
            </Box>
            {artworkData?.status !== 'SOLD' ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <Box
                  sx={{
                    border: 1,
                    borderColor: 'lightgrey',
                    color: 'primary.contrastText',
                    height: { xs: '15rem', sm: '12rem' },
                    width: { xs: '100%', sm: '80%' },
                    display: 'flex',
                    flexDirection: { xs: 'row' },
                    justifyContent: { xs: 'space-between' },
                    alignItems: 'center',
                    paddingLeft: '2rem',
                    paddingRight: '2rem',
                    paddingY: { xs: '1rem', sm: '3rem' },
                  }}
                >
                  {/* <Typography variant="h3">SOLD</Typography> */}
                  <>
                    <Box sx={{ pr: '1rem' }}>
                      <Typography
                        variant="h5"
                        sx={{
                          color: 'black',
                        }}
                      >
                        {artworkData.price.currency.symbol}
                        {artworkData.price.price}
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Box sx={{ minWidth: 120, mb: '1rem' }}>
                        <FormControl variant="filled" fullWidth>
                          <InputLabel id="size">
                            {t('w.artwork.setting.quant')}
                          </InputLabel>
                          <Select
                            labelId="size"
                            variant={'standard'}
                            id="size"
                            value={size}
                            label="quantity"
                            onChange={handleSizeChange}
                          >
                            <MenuItem value={1}>1</MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="in-store"
                            onChange={checkboxChangeHandler}
                          />
                        }
                        label={t('s.merchandise.func.in-store-pickup')}
                      />
                      <Button
                        variant={'outlined'}
                        color={'success'}
                        sx={{
                          marginBottom: '1rem',
                          borderRadius: '0',
                          mt: '0.5rem',
                        }}
                        onClick={() =>
                          addCartHandler?.(artworkId, parseInt(size))
                        }
                        disabled={!size}
                      >
                        {t('s.merchandise.func.add-to-cart')}
                      </Button>
                      <Button
                        variant={'contained'}
                        color={'success'}
                        sx={{ borderRadius: '0' }}
                        onClick={order}
                        disabled={!size}
                      >
                        {t('s.merchandise.func.buy-now')}
                      </Button>
                    </Box>
                  </>
                </Box>
              </Box>
            ) : (
              <Box>
                <Typography variant="h4" sx={{ mt: '2rem', mb: '2rem' }}>
                  {t('w.artwork.info.sold')}
                </Typography>
              </Box>
            )}
            <Box sx={{ marginTop: '1rem' }}>
              <Typography>
                X {t('s.merchandise.info.shipping-excluded')}
              </Typography>
              {/* <Typography>(heart icon) 256</Typography> */}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default IndividualArtWorkCard;
