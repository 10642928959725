import Category from '../../components/category/Category';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const CategoryMonetaryPage = () => {
  const { t } = useTranslation();
  return (
    <Box>
      <Typography variant="h4" sx={{ ml: '2rem', mt: '6rem' }}>
        {t(`t.main.category.shop-by-category`)}
      </Typography>
      <Category type="nonmonetary" />
    </Box>
  );
};

export default CategoryMonetaryPage;
