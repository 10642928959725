import AnimatedWrapper from 'components/common/AnimatedWrapper';
import KakaoMap from '../components/maps/KakaoMap';
import { Box, Typography, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';

const MapsPage = () => {
  const { t } = useTranslation();
  return (
    <AnimatedWrapper>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box sx={{ width: '80%' }}>
          <Typography variant="h4">{t(`w.map.info.location`)}</Typography>
          <Divider sx={{ marginBottom: '1rem', marginTop: '0.5rem' }} />
          <KakaoMap />
        </Box>
      </Box>
    </AnimatedWrapper>
  );
};

export default MapsPage;
