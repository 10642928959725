import { useState } from 'react';
import {
  Button,
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
} from '@mui/material';
import ContactSomaModal from 'components/common/ContactSomaModal';
import { useTranslation } from 'react-i18next';

const CuratorBigImgSection = () => {
  const { t } = useTranslation();
  const [contactOpen, setContactOpen] = useState(false);

  const handleContactOpen = () => setContactOpen(true);
  const handleContactClose = () => setContactOpen(false);

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Card
        square={true}
        elevation={0}
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', lg: 'row' },
          height: 'auto',
          width: '80%',
          margin: { xs: '1rem', lg: '100px' },
        }}
      >
        <CardMedia
          component="img"
          sx={{ width: { xs: '100%', lg: '50%' }, height: 'auto' }}
          image="https://gallery-soma-assets.s3.ap-northeast-2.amazonaws.com/common/hero_image_of_ceo.jpeg"
          alt="an image of the chief curator of the gallery SOMA"
        />
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            overflow: 'auto',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            <Typography component={'span'} variant="body2">
              {t(`s.main.curator.vision-statement`)}
            </Typography>
            <Typography
              variant="subtitle1"
              color="text.secondary"
              component={'div'}
            >
              {t(`w.main.curator.name`)}
            </Typography>
            <Button
              variant="outlined"
              onClick={handleContactOpen}
              sx={{
                borderRadius: '0',
                marginTop: '1rem',
                color: 'black',
                transition: 'all 0.3s ease',
                '&:hover': {
                  backgroundColor: '#848081',
                  color: '#ffffff',
                  transform: 'scale(1.05)',
                  boxShadow: '0 8px 16px rgba(0, 0, 0, 0.3)',
                },
              }}
            >
              {t(`s.main.curator.cooperation`)}
            </Button>
          </Box>
        </CardContent>
      </Card>
      <ContactSomaModal open={contactOpen} handleClose={handleContactClose} />
    </Box>
  );
};

export default CuratorBigImgSection;
