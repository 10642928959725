import { useEffect, useState, useCallback } from 'react';
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import ExhibitionArtWorkCard from '../components/exhibitions/ExhibitionArtWorkCard';
import { BasicResponse } from '../models/common';
import Exhibition from '../models/exhibition/Exhibition';
import { ApiServer } from '../utils/axios';
import ExhibitionNavigator from '../components/exhibitions/ExhibitionNavigator';
import useMountEffect from 'hooks/useMountEffect';
import AnimatedWrapper from 'components/common/AnimatedWrapper';
import { useTranslation } from 'react-i18next';

const ExhibitionPage = () => {
  const [exhibitions, setExhibitions] = useState<Exhibition[]>([]);
  const [filteredExhibition, setFilteredExhibition] = useState<Exhibition[]>(
    []
  );
  const [tense, setTense] = useState<keyof typeof tenses>('ONGOING');
  const { t } = useTranslation();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const tenses = {
    PAST: t('s.exhibition.info.no-past-exhibition'),
    ONGOING: t('s.exhibition.info.no-ongoing-exhibition'),
    UPCOMING: t('s.exhibition.info.no-upcoming-exhibition'),
  };

  const isOngoing = (startDate: string, endDate: string) => {
    const today = new Date();
    return new Date(startDate) <= today && today <= new Date(endDate);
  };

  useMountEffect(() => window.scrollTo(0, 0), []);

  useEffect(() => {
    ApiServer.get<BasicResponse<Exhibition[]>>('/exhibition-career/all')
      .then(({ data: { data, status } }) => {
        if (status.code === 'OK') {
          const filteredExhibitions = data.filter(
            (item) => item.category.name !== 'Art Fair'
          );
          setExhibitions(filteredExhibitions);
          setFilteredExhibition(
            filteredExhibitions.filter((item) =>
              isOngoing(item.startDate, item.endDate)
            )
          );
        } else {
          console.log(status.description);
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);

  const buttonClickHandler = useCallback(
    (value: keyof typeof tenses) => {
      setTense(value);
      const today: Date = new Date();
      if (value === 'PAST') {
        setFilteredExhibition(
          exhibitions.filter((item) => new Date(item.endDate) < today)
        );
      } else if (value === 'ONGOING') {
        setFilteredExhibition(
          exhibitions.filter(
            (item) =>
              new Date(item.startDate) <= today &&
              today <= new Date(item.endDate)
          )
        );
      } else {
        setFilteredExhibition(
          exhibitions.filter((item) => today < new Date(item.startDate))
        );
      }
      window.scrollTo(0, 0); // 페이지의 최상단으로 스크롤
    },
    [exhibitions]
  );

  return (
    <AnimatedWrapper>
      <Box
        sx={{
          maxWidth: '1200px',
          mx: 'auto',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <ExhibitionNavigator
          onButtonClick={buttonClickHandler}
          id={t('w.main.navmenu.exhibition')}
        />
        <Box
          sx={{
            marginTop: '5rem',
            width: { xs: '100%', sm: '40rem', md: '50rem' },
          }}
        >
          <Grid
            container
            spacing={{ xs: 1, md: 2, lg: 3 }}
            sx={{ marginTop: '1rem', justifyContent: 'center' }}
          >
            {filteredExhibition.length > 0 &&
              filteredExhibition
                .slice(0)
                .reverse()
                .map((exhibition: Exhibition) => (
                  <ExhibitionArtWorkCard
                    key={exhibition.id}
                    exhibition={exhibition}
                  />
                ))}
          </Grid>
        </Box>
        {filteredExhibition.length === 0 && (
          <Typography sx={{ mt: '5rem', textAlign: 'center' }}>
            {tenses[tense]}
          </Typography>
        )}
      </Box>
    </AnimatedWrapper>
  );
};

export default ExhibitionPage;
