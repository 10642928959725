import { useEffect, useState, lazy, Suspense } from 'react';
import { Box, Pagination, CircularProgress, Typography } from '@mui/material';
import CategorySideToImages from '../components/category/CategorySideToImages';
import { ApiServer } from '../utils/axios';
import BasicResponse from '../models/common/BasicResponse';
import PageableData from '../models/pageable/PageableData';
import Category from '../models/category/Category';
import Artist from '../models/artist/Artist';
import { FilterCategory, FilterType } from '../store/filter-redux';
import Page from '../models/pageable/Page';
import { useSelector } from 'react-redux';
import { RootState } from '../store/redux';
import ArtWorkItem from '../models/artwork/ArtWorkItem';
import useMountEffect from '../hooks/useMountEffect';

// Lazy load MerchPageImages
const MerchPageImages = lazy(() => import('../components/Images/MerchPageImages'));

type SearchFilter = {
  artist: string;
  category: string;
  keyword: string;
  minPrice: string | null;
  maxPrice: string | null;
};

const MerchPage = () => {
  const filters = useSelector((state: RootState) => state.filter.filters);
  const [categoryArr, setCategoryArr] = useState<FilterType[]>([]);
  const [artistArr, setArtistArr] = useState<FilterType[]>([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [filter, setFilter] = useState<Page & Partial<SearchFilter>>(() => {
    const initialFilter: Page & Partial<SearchFilter> = {
      page: 0,
      size: 20,
      artist: filters
          .filter((f) => f.category === FilterCategory.ARTIST)
          .map((f) => f.id)
          .join(),
      category: filters
          .filter((f) => f.category === FilterCategory.CATEGORY)
          .map((f) => f.id)
          .join(),
      keyword: undefined,
      minPrice: null,
      maxPrice: null,
    };

    const priceFilter = filters.find((f) => f.category === FilterCategory.PRICE);
    if (priceFilter) {
      switch (priceFilter.id) {
        case 1:
          initialFilter.minPrice = null;
          initialFilter.maxPrice = '500000';
          break;
        case 2:
          initialFilter.minPrice = '500000';
          initialFilter.maxPrice = '1000000';
          break;
        case 3:
          initialFilter.minPrice = '1000000';
          initialFilter.maxPrice = '2000000';
          break;
        case 4:
          initialFilter.minPrice = '2000000';
          initialFilter.maxPrice = null;
          break;
        default:
          initialFilter.minPrice = null;
          initialFilter.maxPrice = null;
      }
    }

    return initialFilter;
  });

  const [artworkItems, setArtworkItems] = useState<PageableData<ArtWorkItem[]> | undefined>(undefined);

  const fetchArtworks = async (filterParams: Page & Partial<SearchFilter>) => {
    setIsLoading(true);
    setError(null);
    try {
      const { data: { data } } = await ApiServer.get<BasicResponse<PageableData<ArtWorkItem[]>>>('/artwork/item/all', { params: { ...filterParams } });
      setArtworkItems(data);
      setTotalPages(data.totalPages);
      console.log('Fetched artwork data:', data);
    } catch (e) {
      console.error('Error fetching all artworks:', e);
      setError('Failed to fetch artworks. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  useMountEffect(() => {
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    const fetchCategoriesAndArtists = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const [categoryRes, artistRes] = await Promise.all([
          ApiServer.get<BasicResponse<Category[]>>('/artwork/category/all'),
          ApiServer.get<BasicResponse<Artist[]>>('/artist/all')
        ]);

        if (categoryRes.data.status?.code === 'OK') {
          const categoryData = categoryRes.data.data.map((item: Category) => ({
            name: item.name,
            id: item.id,
            content: item.name,
            category: FilterCategory.CATEGORY,
          }));
          setCategoryArr(categoryData);
        } else {
          console.error(categoryRes.data.status.description);
          setError('Failed to fetch categories.');
        }

        if (artistRes.data.status?.code === 'OK') {
          const artistData = artistRes.data.data.map((item: Artist) => ({
            name: item.name,
            id: item.id,
            content: item.name,
            category: FilterCategory.ARTIST,
          }));
          setArtistArr(artistData);
        } else {
          console.error(artistRes.data.status.description);
          setError('Failed to fetch artists.');
        }
      } catch (e) {
        console.error('Error fetching categories and artists:', e);
        setError('Failed to fetch categories and artists. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchCategoriesAndArtists();
  }, []);

  useEffect(() => {
    if (categoryArr.length > 0 && artistArr.length > 0) {
      console.log('Applying filters:', filters);

      const mediaFilters = filters.filter(item => item.category === FilterCategory.CATEGORY);
      const priceFilters = filters.filter(item => item.category === FilterCategory.PRICE);
      const artistFilters = filters.filter(item => item.category === FilterCategory.ARTIST);
      const keywordFilters = filters.filter(item => item.category === FilterCategory.KEYWORD);

      const mediaFilterIds = mediaFilters.map(item => item.id);
      const artistFilterIds = artistFilters.map(item => item.id);

      let minPrice: string | null = null;
      let maxPrice: string | null = null;

      if (priceFilters.length !== 0) {
        switch (priceFilters[0].id) {
          case 1:
            minPrice = null;
            maxPrice = '500000';
            break;
          case 2:
            minPrice = '500000';
            maxPrice = '1000000';
            break;
          case 3:
            minPrice = '1000000';
            maxPrice = '2000000';
            break;
          case 4:
            minPrice = '2000000';
            maxPrice = null;
            break;
          default:
            minPrice = null;
            maxPrice = null;
        }
      }

      setFilter((prev) => ({
        ...prev,
        artist: artistFilterIds.join(),
        category: mediaFilterIds.join(),
        minPrice: minPrice,
        maxPrice: maxPrice,
        keyword: keywordFilters[0]?.content,
      }));
    }
  }, [filters, categoryArr, artistArr]);

  useEffect(() => {
    if (categoryArr.length > 0 && artistArr.length > 0) {
      console.log('Current filter state:', filter);
      fetchArtworks(filter);
    }
  }, [filter, categoryArr, artistArr]);

  if (isLoading) {
    return (
        <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100vh',
            }}
        >
          <CircularProgress />
        </Box>
    );
  }

  if (error) {
    return (
        <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100vh',
            }}
        >
          <Typography variant="h6" color="error">
            {error}
          </Typography>
        </Box>
    );
  }

  return (
      <>
        <Box
            sx={{
              marginLeft: '1rem',
              marginRight: '1rem',
              marginTop: '3rem',
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: { xs: 'column', sm: 'row' },
            }}
        >
          <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'row', sm: 'column' },
              }}
          >
            <CategorySideToImages categoryArr={categoryArr} artistArr={artistArr} />
          </Box>
          <Suspense
              fallback={
                <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100vh',
                      marginTop: '2rem',
                    }}
                >
                  <CircularProgress />
                </Box>
              }
          >
            <MerchPageImages data={artworkItems?.content} />
          </Suspense>
        </Box>
        <Pagination
            sx={{ display: 'flex', justifyContent: 'center' }}
            count={totalPages}
            page={filter.page + 1}
            onChange={(_, page) =>
                setFilter((prev) => ({ ...prev, page: page - 1 }))
            }
        />
      </>
  );
};

export default MerchPage;
