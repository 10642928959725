import { Box, Typography, IconButton, Radio } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { UserAddress as UserAddressType } from "models/user";

type MyAddressesProps = {
  addresses?: UserAddressType[];
  onDelete: (id: number) => void;
  onSelectDefault: (id: number) => void; // New prop for selecting default address
  defaultAddressId?: number; // Optional prop to indicate current default address
};

const MyAddresses = ({
  addresses,
  onDelete,
  onSelectDefault,
  defaultAddressId,
}: MyAddressesProps) => {
  return (
    <Box>
      {addresses?.map((address) => (
        <Box
          key={address.id}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 2,
            border: "1px solid #ccc",
            borderRadius: 2,
            mb: 2,
          }}
        >
          <Box>
            <Typography variant="h6">{address.name}</Typography>
            <Typography variant="body2">{address.receiver}</Typography>
            <Typography variant="body2">{address.street}</Typography>
            <Typography variant="body2">{address.city}</Typography>
            <Typography variant="body2">{address.state}</Typography>
            <Typography variant="body2">{address.zip}</Typography>
            <Typography variant="body2">{address.contact}</Typography>
            <Typography variant="body2">{address.country}</Typography>
          </Box>
          <Box>
            <Radio
              checked={address.id === defaultAddressId}
              onChange={() => onSelectDefault(address.id)}
              color="primary"
              name={`default-address-${address.id}`}
              inputProps={{
                "aria-label": `Set ${address.name} as default address`,
              }}
            />
            <IconButton onClick={() => onDelete(address.id)} color="secondary">
              <DeleteIcon />
            </IconButton>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default MyAddresses;
