import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import "./VideoBackground.css";

type VideoBackgroundProps = {
  backgroundClickHandler: () => void;
};
const VideoBackground = ({ backgroundClickHandler }: VideoBackgroundProps) => {
  const [showSkipButton, setShowSkipButton] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSkipButton(true);
    }, 3000); // 3000 milliseconds = 3 seconds

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={"video-background hideOnPhone"} onClick={backgroundClickHandler}>
      <video autoPlay loop muted>
        <source src="https://gallery-soma-assets.s3.ap-northeast-2.amazonaws.com/test1/videos/aurora_borealis_-_90877+(Original).mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="overlay">
        <Box sx={{ display: "flex", flexDirection: "column"}}>
          <img
            src="https://gallery-soma-assets.s3.ap-northeast-2.amazonaws.com/logo/soma_logo_lightgrey.png"
            alt="gallery soma logo"
          />
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <p
              className={showSkipButton ? "skip-text visible" : "skip-text"}
              onClick={backgroundClickHandler}
            >
              click anywhere to skip ⋙
            </p>
          </Box>
        </Box>
        {/*<h1 className="galery-title">Gallery soma</h1>*/}
      </div>
    </div>
  );
};

export default VideoBackground;
