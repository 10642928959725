import styles from './ToggleSwitch.module.css';

type ToggleSwitchProps = {
  isOn: boolean;
  onToggle: (isOn: boolean) => void;
};

const ToggleSwitch = ({ isOn, onToggle }: ToggleSwitchProps) => {
  const handleToggle = () => {
    onToggle(!isOn);
  };

  return (
    <div
      className={`${styles['toggle-switch']} ${isOn ? styles['on'] : ''}`}
      onClick={handleToggle}
    >
      <div
        className={`${styles['toggle-switch-knob']} ${
          isOn ? styles['on'] : styles['off']
        }`}
      ></div>
    </div>
  );
};

export default ToggleSwitch;
