import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BasicResponse } from 'models/common';
import { ArtworkOrder } from 'models/order';
import { ApiServer } from '../../utils/axios';
import { useTranslation } from 'react-i18next';
import {useNavigate} from "react-router-dom";
import {Container, Paper, Button} from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

function OrderResultPage() {
  const { orderId } = useParams();
  const [order, setOrder] = useState<ArtworkOrder | undefined>(undefined);
//   const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    ApiServer.get<BasicResponse<ArtworkOrder>>(`/order/${orderId}`)
      .then(({ data: { data } }) => {
        setOrder(data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [orderId]);

  const continueHandler = () => {
      navigate("/merchandises");
  }

    return (
        <Container maxWidth="sm">
            <Paper elevation={3} sx={{ padding: 4, marginTop: 8, textAlign: 'center' }}>
                <CheckCircleIcon sx={{ fontSize: 60, color: 'green' }} />
                <Typography variant="h4" gutterBottom>
                    Order Complete!
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Thank you for your purchase. Your order number is #12345.
                </Typography>
                <Box mt={4}>
                    <Button variant="contained" color="primary" sx={{ marginRight: 2 }}>
                        View Order
                    </Button>
                    <Button variant="outlined" color="primary" onClick={continueHandler}>
                        Continue Shopping
                    </Button>
                </Box>
            </Paper>
        </Container>
    );
}

export default OrderResultPage;
