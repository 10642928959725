import { useState } from 'react';
import { Map, MapMarker } from 'react-kakao-maps-sdk';
import ShortcutIcon from '@mui/icons-material/Shortcut';
import { Typography, Box, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import styles from './KakaoMap.module.css';
import { useTranslation } from 'react-i18next';

const KakaoMap = () => {
  const [level, setLevel] = useState(2);
  const { t } = useTranslation();

  const locationInfo = {
    LATITUDE: 37.646542,
    LONGITUDE: 126.914728,
  };
  Object.freeze(locationInfo);

  const addyAndFindLocation = (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '1rem',
          flexDirection: { xs: 'column', sm: 'row' },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            mb: { xs: 2, sm: 0 },
          }}
        >
          <Typography variant={'body1'}>
            {t(`w.common.info.gallery-name`)}
          </Typography>
          <Typography
            sx={{
              fontSize: {
                xs: 'small',
                sm: 'large',
                md: 'x-large',
                xl: 'x-large',
              },
              fontWeight: '500',
            }}
          >
            경기도 고양시 덕양구 안진5길 5 (지축동) 101호 (우편번호 10585)
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row-reverse',
          }}
        >
          <Button
            variant={'contained'}
            startIcon={<ShortcutIcon />}
            sx={{
              borderRadius: '8px',
              padding: '6px 16px',
              fontSize: {
                xs: '0.75rem',
                sm: '0.875rem',
              },
              marginLeft: 2,
            }}
            href={`https://map.kakao.com/link/to/갤러리쏘마,${locationInfo.LATITUDE},${locationInfo.LONGITUDE}`}
            target="_blank"
          >
            {t(`w.map.func.find-direction`)}
          </Button>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <button
              className={`${styles['button-80']} ${styles['button-left']}`}
              onClick={() => setLevel(level + 1)}
            >
              -
            </button>
            <button
              className={styles['button-80']}
              onClick={() => setLevel(level - 1)}
            >
              +
            </button>
          </Box>
        </Box>
      </Box>
    </Box>
  );

  return (
    <Box sx={{ width: '100%', position: 'relative' }}>
      <Map
        center={{ lat: locationInfo.LATITUDE, lng: locationInfo.LONGITUDE }} // 지도의 중심 좌표
        style={{ width: '100%', height: '500px' }} // 지도 크기
        level={level} // 지도 확대 레벨
      >
        <MapMarker
          position={{ lat: locationInfo.LATITUDE, lng: locationInfo.LONGITUDE }}
        ></MapMarker>
        {addyAndFindLocation}
      </Map>
    </Box>
  );
};
export default KakaoMap;
